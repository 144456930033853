import React from "react";
import { Helmet } from "react-helmet";
import AdminSideBar from "components/AdminSideBar";
import MobileNavbar from "components/MobileNavbar";
import { useNavigate } from "react-router-dom";

function AdminModifyIndividualComplete() {
  const navigate = useNavigate();

  const handleMoveInquiry = () => {
    navigate("/admin-search", { replace: true });
  };

  const handleMoveAddIndividual = () => {
    navigate("/admin-add-individual", { replace: true });
  };
  return (
    <>
      <MobileNavbar />
      <Helmet>
        <title>ycf관리자 - 개인참가자 정보 수정완료</title>
      </Helmet>
      <div className="d-flex">
        <AdminSideBar />
        <div className="container text-center mt-5">
          <div className="row">
            <div className="col individual-com">
              <p>개인참가신청</p>
              <p>완료</p>
            </div>
          </div>
          <div className="row justify-content-center mt-5">
            <div className="col-lg-6">
              <button
                className="btn-size btn-add-individual"
                onClick={() => {
                  handleMoveAddIndividual();
                }}
              >
                개인참가자 추가하기 페이지 이동
              </button>
            </div>
          </div>
          <div className="row justify-content-center mt-3">
            <div className="col-lg-6">
              <button
                className="btn-size btn-inquiry"
                onClick={() => {
                  handleMoveInquiry();
                }}
              >
                참가자 검색 페이지 이동
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AdminModifyIndividualComplete;
