import React, { useRef, useEffect, useState } from "react";
import ExtraBold from "components/ExtraBold";
import { Helmet } from "react-helmet";
import AdminSideBar from "components/AdminSideBar";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { useAxios } from "hooks/useAxios";
import { useVerify } from "hooks/useVerify";
import { useNavigate, useSearchParams } from "react-router-dom";
import "assets/css/DetailBoard.css";
import Spinner from "components/Spinner";
import MobileNavbar from "components/MobileNavbar";

function AdminDetailQuestion2() {
  const [detailQuestionData, setDetailQuestionData] = useState([]);
  const [comment, setComment] = useState([]);
  const { accessAxios } = useAxios();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [query, setQuery] = useSearchParams();
  const { isVerify } = useVerify();
  const commentRef = useRef();

  const fetchingData = async () => {
    let ignore = false;
    const id = query.get("id");
    try {
      if (!ignore) {
        setLoading(true);
      }
      const result = await accessAxios.post("/api/get-detail-question", {
        id,
      });
      if (!ignore) {
        setDetailQuestionData(result.data);
        setLoading(false);
      }
    } catch (error) {
      if (!ignore) {
        setLoading(false);
      }
      console.log(error);
      if (error.code === "ERR_BAD_RESPONSE" || error.code === "ECONNABORTED") {
        Swal.fire({
          icon: "error",
          title: "서버 에러",
          text: "관리자에게 문의해주세요!!!",
        });
      }
    }
  };
  const commentFetchingData = async () => {
    let igonre = false;
    try {
      if (!igonre) {
        setLoading(true);
      }
      const result = await accessAxios.post("/api/question-comment", {
        id: query.get("id"),
      });
      if (!igonre) {
        setComment(result.data);
        setLoading(false);
      }
    } catch (error) {
      if (!igonre) {
        setLoading(false);
      }
      console.log(error);
      if (error.code === "ERR_BAD_RESPONSE" || error.code === "ECONNABORTED") {
        Swal.fire({
          icon: "error",
          title: "서버 에러",
          text: "관리자에게 문의해주세요!!!",
        });
      }
    }
  };

  const commentMap = () => {
    const createComment = comment.map((data) => (
      <div key={data.QUESTION_COMMENT_ID}>
        <p className="bold-font mt-3">관리자</p>
        <p>{data.QUESTION_COMMENT}</p>
        <p style={{ fontSize: "12px", color: "#999" }}>
          {data.QUESTION_COMMENT_CREATED_AT}
        </p>
        {isVerify ? (
          <div>
            <span className="ms-2">
              <button
                type="button"
                className="btn btn-outline-danger"
                onClick={() => {
                  deleteComment(data);
                }}
              >
                삭제
              </button>
            </span>
          </div>
        ) : (
          <></>
        )}
        <div className="border-bottom"></div>
      </div>
    ));

    return createComment;
  };

  const deleteComment = (data) => {
    Swal.fire({
      title: `문의사항 댓글 삭제`,
      text: `문의사항 댓글을 삭제하시겠습니까?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "네, 삭제합니다.",
      cancelButtonText: "아니오",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          setLoading(true);
          const result = await accessAxios.post(
            "/api/delete-question-comment",
            {
              boardId: query.get("id"),
              commentId: data.QUESTION_COMMENT_ID,
            }
          );
          if (result.data.resultCode === "00") {
            setLoading(false);
            Swal.fire({
              title: "삭제완료",
              text: `문의사항 댓글이 삭제되었습니다.`,
              icon: "success",
              timer: 1500,
              showConfirmButton: false,
            });
            commentFetchingData();
          } else {
            setLoading(false);
            Swal.fire({
              icon: "error",
              title: "댓글 삭제 실패!!!",
              text: "관리자에게 문의해주세요!!!",
            });
          }
        } catch (error) {
          setLoading(false);
          console.log(error);
          if (
            error.code === "ERR_BAD_RESPONSE" ||
            error.code === "ECONNABORTED"
          ) {
            Swal.fire({
              icon: "error",
              title: "서버 에러",
              text: "관리자에게 문의해주세요!!!",
            });
          }
        }
      }
    });
  };

  const writeComment = async (e) => {
    let pass = false;

    if (commentRef.current.value === "") {
      Swal.fire({
        icon: "error",
        title: "내용을 입력해주세요.",
        text: "댓글 내용을 입력해주세요.",
      });
    } else {
      pass = true;
    }

    try {
      if (pass) {
        setLoading(true);
        const result = await accessAxios.post("/api/write-question-comment", {
          boardId: query.get("id"),
          boardComment: commentRef.current.value,
        });

        if (result.data.resultCode === "00") {
          commentRef.current.value = "";
          setLoading(false);
          commentFetchingData();
        } else {
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: "댓글 달기 실패!!!",
            text: "관리자에게 문의해주세요!!!",
          });
        }
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      if (error.code === "ERR_BAD_RESPONSE" || error.code === "ECONNABORTED") {
        Swal.fire({
          icon: "error",
          title: "서버 에러",
          text: "관리자에게 문의해주세요!!!",
        });
      }
    }
  };

  const handleOnKeyPress = (e) => {
    if (e.isComposing || e.keyCode === 229) return;
    if (e.key === "Enter") {
      writeComment();
    }
  };

  const moveList = () => {
    navigate(`/admin-question2?page=${query.get("page")}`);
  };

  const deleteQuestion = () => {
    Swal.fire({
      title: `문의사항 게시글 삭제`,
      text: `문의사항 게시글을 삭제하시겠습니까?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "네, 삭제합니다.",
      cancelButtonText: "아니오",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          setLoading(true);
          const result = await accessAxios.post("/api/delete-question", {
            id: query.get("id"),
          });
          if (result.data.resultCode === "00") {
            setLoading(false);
            Swal.fire({
              title: "삭제완료",
              text: `문의사항 게시글이 삭제되었습니다.`,
              icon: "success",
              timer: 1500,
              showConfirmButton: false,
            });
            navigate(`/admin-question2?page=${query.get("page")}`, {
              replace: true,
            });
          } else {
            setLoading(false);
            Swal.fire({
              icon: "error",
              title: "게시글 삭제 실패!!!",
              text: "관리자에게 문의해주세요!!!",
              showConfirmButton: true,
            });
          }
        } catch (error) {
          setLoading(false);
          console.log(error);
          if (
            error.code === "ERR_BAD_RESPONSE" ||
            error.code === "ECONNABORTED"
          ) {
            Swal.fire({
              icon: "error",
              title: "서버 에러",
              text: "관리자에게 문의해주세요!!!",
            });
          }
        }
      }
    });
  };

  const questionStatus = async (e) => {
    try {
      setLoading(true);
      const result = await accessAxios.post("/api/question-state", {
        id: query.get("id"),
        state: e.target.value,
      });

      if (result.data.resultCode === "00") {
        fetchingData();

        setLoading(false);
        if (e.target.value === "wait") {
          Swal.fire({
            title: "상태변경 완료",
            text: "답변대기 상태가 되었습니다..",
            icon: "success",
            timer: 1500,
            showConfirmButton: false,
          });
        } else if (e.target.value === "ing") {
          Swal.fire({
            title: "상태변경 완료",
            text: "답변중 상태가 되었습니다..",
            icon: "success",
            timer: 1500,
            showConfirmButton: false,
          });
        } else if (e.target.value === "complete") {
          Swal.fire({
            title: "상태변경 완료",
            text: "단변완료 상태가 되었습니다..",
            icon: "success",
            timer: 1500,
            showConfirmButton: false,
          });
        }
      } else {
        Swal.fire({
          icon: "error",
          title: "상태변경 에러",
          text: "관리자에게 문의해주세요!!!",
        });
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      if (error.code === "ERR_BAD_RESPONSE" || error.code === "ECONNABORTED") {
        Swal.fire({
          icon: "error",
          title: "서버 에러",
          text: "관리자에게 문의해주세요!!!",
        });
      }
    }
  };

  useEffect(() => {
    let igonre = false;

    const fetchingData = async () => {
      const id = query.get("id");
      try {
        if (!igonre) {
          setLoading(true);
        }
        const result = await accessAxios.post("/api/get-detail-question", {
          id,
        });
        if (!igonre) {
          setDetailQuestionData(result.data);
          setLoading(false);
        }
      } catch (error) {
        if (!igonre) {
          setLoading(false);
        }
        console.log(error);
        if (
          error.code === "ERR_BAD_RESPONSE" ||
          error.code === "ECONNABORTED"
        ) {
          Swal.fire({
            icon: "error",
            title: "서버 에러",
            text: "관리자에게 문의해주세요!!!",
          });
        }
      }
    };

    const commentFetchingData = async () => {
      try {
        if (!igonre) {
          setLoading(true);
        }
        const result = await accessAxios.post("/api/question-comment", {
          id: query.get("id"),
        });
        if (!igonre) {
          setComment(result.data);
          setLoading(false);
        }
      } catch (error) {
        if (!igonre) {
          setLoading(false);
        }
        console.log(error);
        if (
          error.code === "ERR_BAD_RESPONSE" ||
          error.code === "ECONNABORTED"
        ) {
          Swal.fire({
            icon: "error",
            title: "서버 에러",
            text: "관리자에게 문의해주세요!!!",
          });
        }
      }
    };
    commentFetchingData();
    fetchingData();

    return () => {
      igonre = true;
    };
  }, []);

  return (
    <div>
      {loading ? <Spinner /> : <></>}
      <MobileNavbar />
      <div className="d-flex">
        <AdminSideBar />
        <Helmet>
          <title>ycf관리자 - 문의사항 상세보기</title>
        </Helmet>
        <div className="container mt-5">
          <ExtraBold name="문의사항" />
          <div className="row extar-bold-font detail-board-title">
            <div className="col">{detailQuestionData[0]?.QUESTION_TITLE}</div>
          </div>
          <div className="row text-end bold-font detail-writer">
            <div className="col">
              {detailQuestionData[0]?.QUESTION_WRITER}
              &nbsp;&nbsp;
              {detailQuestionData[0]?.QUESTION_CREATED_AT}
            </div>
          </div>
          <br />
          <div className="row">
            <div
              className="col img-size-adjust"
              dangerouslySetInnerHTML={{
                __html: detailQuestionData[0]?.QUESTION_CONTENT,
              }}
            ></div>
          </div>
          <br />
          <div className="row justify-content-end text-end bold-font">
            <div className="col">
              <button
                type="button"
                className="btn btn-outline-primary me-2"
                onClick={moveList}
              >
                목록
              </button>
              {isVerify ? (
                detailQuestionData[0]?.QUESTION_STATUS === "wait" ? (
                  <>
                    <button
                      type="button"
                      className="btn btn-outline-warning me-2"
                      value={"ing"}
                      onClick={(e) => {
                        questionStatus(e);
                      }}
                    >
                      답변중 처리하기
                    </button>
                    <button
                      type="button"
                      className="btn btn-outline-info me-2"
                      value={"complete"}
                      onClick={(e) => {
                        questionStatus(e);
                      }}
                    >
                      답변완료 처리하기
                    </button>
                  </>
                ) : detailQuestionData[0]?.QUESTION_STATUS === "ing" ? (
                  <>
                    <button
                      type="button"
                      className="btn btn-outline-secondary me-2"
                      value={"wait"}
                      onClick={(e) => {
                        questionStatus(e);
                      }}
                    >
                      답변대기 처리하기
                    </button>
                    <button
                      type="button"
                      className="btn btn-outline-info me-2"
                      value={"complete"}
                      onClick={(e) => {
                        questionStatus(e);
                      }}
                    >
                      답변완료 처리하기
                    </button>
                  </>
                ) : detailQuestionData[0]?.QUESTION_STATUS === "complete" ? (
                  <>
                    <button
                      type="button"
                      className="btn btn-outline-secondary me-2"
                      value={"wait"}
                      onClick={(e) => {
                        questionStatus(e);
                      }}
                    >
                      답변대기 처리하기
                    </button>
                    <button
                      type="button"
                      className="btn btn-outline-warning me-2"
                      value={"ing"}
                      onClick={(e) => {
                        questionStatus(e);
                      }}
                    >
                      답변중 처리하기
                    </button>
                  </>
                ) : (
                  <></>
                )
              ) : (
                <></>
              )}
              {isVerify ? (
                <>
                  <button
                    type="button"
                    className="btn btn-outline-danger"
                    onClick={() => {
                      deleteQuestion();
                    }}
                  >
                    삭제
                  </button>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
          {isVerify ? (
            <div className="row bold-font">
              <div className="col d-flex flex-column mt-5">
                <label htmlFor="comment" className="form-label">
                  문의사항 답변하기
                </label>
                <div className="d-flex">
                  <input
                    type="text"
                    className="form-control me-1"
                    id="comment"
                    style={{ width: "90%" }}
                    ref={commentRef}
                    onKeyDown={handleOnKeyPress}
                  />
                  <button
                    type="button"
                    className="btn btn-outline-dark"
                    onClick={writeComment}
                    style={{ whiteSpace: "nowrap" }}
                  >
                    답변달기
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
          {commentMap().length > 0 ? (
            commentMap()
          ) : (
            <>
              <div className="mt-5"></div>
              <div className="container">
                <div className="row text-center">
                  <div className="col">댓글이 없습니다.</div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default AdminDetailQuestion2;
