import React from "react";
import { Helmet } from "react-helmet";
import AdminSideBar from "components/AdminSideBar";
import MobileNavbar from "components/MobileNavbar";
import ExtraBold from "components/ExtraBold";
import IndividualInputForm3 from "components/IndividualInputForm3";

function AdminModifyIndividual() {
  return (
    <>
      <MobileNavbar />
      <Helmet>
        <title>ycf관리자 - 개인참가자 정보 수정하기</title>
      </Helmet>
      <div className="d-flex">
        <AdminSideBar />
        <div className="mt-5">
          <div className="row">
            <ExtraBold name="개인참가자 정보 수정하기" />
          </div>
          <IndividualInputForm3 />
        </div>
      </div>
    </>
  );
}

export default AdminModifyIndividual;
