import React, { useRef, useEffect, useState } from "react";
import Header from "components/Header";
import Footer from "components/Footer";
import Bread from "components/Bread";
import ExtraBold from "components/ExtraBold";
import { Helmet } from "react-helmet";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { useAxios } from "hooks/useAxios";
import { useVerify } from "hooks/useVerify";
import { useNavigate, useSearchParams } from "react-router-dom";
import "assets/css/DetailBoard.css";
import Spinner from "components/Spinner";

function DetailRefund2() {
  const [detailRefundData, setDetailRefundData] = useState([]);
  const [comment, setComment] = useState([]);
  const { accessAxios } = useAxios();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [query, setQuery] = useSearchParams();
  const { isVerify } = useVerify();
  const commentRef = useRef();

  const fetchingData = async () => {
    const id = query.get("id");
    try {
      setLoading(true);

      const result = await accessAxios.post("/api/get-detail-refund", {
        id,
      });
      setDetailRefundData(result.data);

      setLoading(false);
    } catch (error) {
      setLoading(false);

      console.log(error);
      if (error.code === "ERR_BAD_RESPONSE" || error.code === "ECONNABORTED") {
        Swal.fire({
          icon: "error",
          title: "서버 에러",
          text: "관리자에게 문의해주세요!!!",
        });
      }
    }
  };

  const commentFetchingData = async () => {
    let igonre = false;
    try {
      if (!igonre) {
        setLoading(true);
      }
      const result = await accessAxios.post("/api/refund-comment", {
        id: query.get("id"),
      });
      if (!igonre) {
        setComment(result.data);
        setLoading(false);
      }
    } catch (error) {
      if (!igonre) {
        setLoading(false);
      }
      console.log(error);
      if (error.code === "ERR_BAD_RESPONSE" || error.code === "ECONNABORTED") {
        Swal.fire({
          icon: "error",
          title: "서버 에러",
          text: "관리자에게 문의해주세요!!!",
        });
      }
    }
  };

  const commentMap = () => {
    const createComment = comment.map((data) => (
      <div key={data.REFUND_COMMENT_ID}>
        <p className="mt-3" style={{ fontWeight: "bold" }}>
          {data.REFUND_COMMENT_WRITER}
        </p>
        <p style={{ fontSize: "14px" }}>{data.REFUND_COMMENT}</p>
        <p style={{ fontSize: "12px", color: "#999" }}>
          {data.REFUND_COMMENT_CREATED_AT}
        </p>
        {isVerify ? (
          <div>
            <span className="ms-2">
              <button
                type="button"
                className="btn btn-outline-danger"
                onClick={() => {
                  deleteComment(data);
                }}
              >
                삭제
              </button>
            </span>
          </div>
        ) : (
          <></>
        )}
        <div className="border-bottom"></div>
      </div>
    ));

    return createComment;
  };

  const deleteComment = (data) => {
    Swal.fire({
      title: `문의사항 댓글 삭제`,
      text: `문의사항 댓글을 삭제하시겠습니까?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "네, 삭제합니다.",
      cancelButtonText: "아니오",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          setLoading(true);
          const result = await accessAxios.post("/api/delete-refund-comment", {
            boardId: query.get("id"),
            commentId: data.REFUND_COMMENT_ID,
          });
          if (result.data.resultCode === "00") {
            setLoading(false);
            Swal.fire({
              title: "삭제완료",
              text: `문의사항 댓글이 삭제되었습니다.`,
              icon: "success",
              timer: 1500,
              showConfirmButton: false,
            });
            commentFetchingData();
          } else {
            setLoading(false);
            Swal.fire({
              icon: "error",
              title: "댓글 삭제 실패!!!",
              text: "관리자에게 문의해주세요!!!",
            });
          }
        } catch (error) {
          setLoading(false);
          console.log(error);
          if (
            error.code === "ERR_BAD_RESPONSE" ||
            error.code === "ECONNABORTED"
          ) {
            Swal.fire({
              icon: "error",
              title: "서버 에러",
              text: "관리자에게 문의해주세요!!!",
            });
          }
        }
      }
    });
  };

  const writeComment = async (e) => {
    let pass = false;

    if (commentRef.current.value === "") {
      Swal.fire({
        icon: "error",
        title: "내용을 입력해주세요.",
        text: "댓글 내용을 입력해주세요.",
      });
    } else {
      pass = true;
    }

    try {
      if (pass) {
        setLoading(true);

        const result = await accessAxios.post("/api/write-refund-comment", {
          boardId: query.get("id"),
          boardWriter: isVerify ? "관리자" : detailRefundData[0].REFUND_WRITER,
          boardComment: commentRef.current.value,
        });

        if (result.data.resultCode === "00") {
          commentRef.current.value = "";
          setLoading(false);
          commentFetchingData();
        } else {
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: "댓글 달기 실패!!!",
            text: "관리자에게 문의해주세요!!!",
          });
        }
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      if (error.code === "ERR_BAD_RESPONSE" || error.code === "ECONNABORTED") {
        Swal.fire({
          icon: "error",
          title: "서버 에러",
          text: "관리자에게 문의해주세요!!!",
        });
      }
    }
  };

  const refundState = async (e) => {
    try {
      setLoading(true);
      const result = await accessAxios.post("/api/refund-state", {
        id: query.get("id"),
        state: e.target.value,
      });

      if (result.data.resultCode === "00") {
        fetchingData();

        if (e.target.value == "wait") {
          setLoading(false);
          Swal.fire({
            title: "환불대기",
            text: "환불대기 상태가 되었습니다..",
            icon: "success",
            timer: 1500,
            showConfirmButton: false,
          });
        } else if (e.target.value == "receipt") {
          setLoading(false);
          Swal.fire({
            title: "환불접수",
            text: "환불접수 상태가 되었습니다..",
            icon: "success",
            timer: 1500,
            showConfirmButton: false,
          });
        } else if (e.target.value == "complete") {
          setLoading(false);
          Swal.fire({
            title: "환불완료",
            text: "환불완료 상태가 되었습니다..",
            icon: "success",
            timer: 1500,
            showConfirmButton: false,
          });
        } else if (e.target.value == "refusal") {
          setLoading(false);
          Swal.fire({
            title: "환불불가",
            text: "환불불가 상태가 되었습니다..",
            icon: "success",
            timer: 1500,
            showConfirmButton: false,
          });
        }
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      if (error.code === "ERR_BAD_RESPONSE" || error.code === "ECONNABORTED") {
        Swal.fire({
          icon: "error",
          title: "서버 에러",
          text: "관리자에게 문의해주세요!!!",
        });
      }
    }
  };

  const handleOnKeyPress = (e) => {
    if (e.isComposing || e.keyCode === 229) return;
    if (e.key === "Enter") {
      writeComment();
    }
  };

  const moveList = () => {
    navigate(`/refund2?page=${query.get("page")}`);
  };

  const deleteRefund = () => {
    Swal.fire({
      title: `환불신청 게시글 삭제`,
      text: `환불신청 게시글을 삭제하시겠습니까?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "네, 삭제합니다.",
      cancelButtonText: "아니오",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          setLoading(true);
          const result = await accessAxios.post("/api/delete-refund", {
            id: query.get("id"),
          });
          if (result.data.resultCode === "00") {
            setLoading(false);
            Swal.fire({
              title: "삭제완료",
              text: `환불신청 게시글이 삭제되었습니다.`,
              icon: "success",
              timer: 1500,
              showConfirmButton: false,
            });
            navigate(`/refund2?page=${query.get("page")}`, { replace: true });
          } else {
            setLoading(false);
            Swal.fire({
              icon: "error",
              title: "게시글 삭제 실패!!!",
              text: "관리자에게 문의해주세요!!!",
              showConfirmButton: true,
            });
          }
        } catch (error) {
          setLoading(false);
          console.log(error);
          if (
            error.code === "ERR_BAD_RESPONSE" ||
            error.code === "ECONNABORTED"
          ) {
            Swal.fire({
              icon: "error",
              title: "서버 에러",
              text: "관리자에게 문의해주세요!!!",
            });
          }
        }
      }
    });
  };

  useEffect(() => {
    let igonre = false;

    const fetchingData = async () => {
      const id = query.get("id");
      try {
        if (!igonre) {
          setLoading(true);
        }
        const result = await accessAxios.post("/api/get-detail-refund", {
          id,
        });
        setDetailRefundData(result.data);
        if (!igonre) {
          setLoading(false);
        }
      } catch (error) {
        if (!igonre) {
          setLoading(false);
        }
        console.log(error);
        if (
          error.code === "ERR_BAD_RESPONSE" ||
          error.code === "ECONNABORTED"
        ) {
          Swal.fire({
            icon: "error",
            title: "서버 에러",
            text: "관리자에게 문의해주세요!!!",
          });
        }
      }
    };

    const commentFetchingData = async () => {
      try {
        if (!igonre) {
          setLoading(true);
        }
        const result = await accessAxios.post("/api/refund-comment", {
          id: query.get("id"),
        });
        if (!igonre) {
          setComment(result.data);
          setLoading(false);
        }
      } catch (error) {
        if (!igonre) {
          setLoading(false);
        }
        console.log(error);
        if (
          error.code === "ERR_BAD_RESPONSE" ||
          error.code === "ECONNABORTED"
        ) {
          Swal.fire({
            icon: "error",
            title: "서버 에러",
            text: "관리자에게 문의해주세요!!!",
          });
        }
      }
    };
    commentFetchingData();
    fetchingData();

    return () => {
      igonre = true;
    };
  }, []);

  return (
    <div>
      {loading ? <Spinner /> : <></>}
      <Helmet>
        <title>ycf - 환불신청 상세보기</title>
      </Helmet>
      <Header />
      <Bread first="환불신청" second={null} />
      <ExtraBold name="환불신청 상세보기" />
      <div className="container mt-5">
        <div className="row extar-bold-font detail-board-title">
          <div className="col">{detailRefundData[0]?.REFUND_TITLE}</div>
        </div>
        <div className="row text-end bold-font detail-writer">
          <div className="col">
            {detailRefundData[0]?.REFUND_WRITER}
            &nbsp;&nbsp;
            {detailRefundData[0]?.REFUND_CREATED_AT}
          </div>
        </div>
        <br />
        <div className="row">
          <div
            className="col img-size-adjust"
            dangerouslySetInnerHTML={{
              __html: detailRefundData[0]?.REFUND_CONTENT,
            }}
          ></div>
        </div>
        <br />
        <div className="row justify-content-end text-end bold-font">
          <div className="col">
            <button
              type="button"
              className="btn btn-outline-primary me-4"
              onClick={moveList}
            >
              목록
            </button>
            {isVerify ? (
              detailRefundData[0]?.REFUND_STATUS == "wait" ? (
                <>
                  <button
                    type="button"
                    className="btn btn-outline-success me-1"
                    value={"receipt"}
                    onClick={refundState}
                  >
                    환불접수 처리하기
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-primary me-1"
                    value={"complete"}
                    onClick={refundState}
                  >
                    환불완료 처리하기
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-danger"
                    value={"refusal"}
                    onClick={refundState}
                  >
                    환불불가 처리하기
                  </button>
                </>
              ) : detailRefundData[0]?.REFUND_STATUS == "receipt" ? (
                <>
                  <button
                    type="button"
                    className="btn btn-outline-secondary me-1"
                    value={"wait"}
                    onClick={refundState}
                  >
                    환불대기 처리하기
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-primary me-1"
                    value={"complete"}
                    onClick={refundState}
                  >
                    환불완료 처리하기
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-danger"
                    value={"refusal"}
                    onClick={refundState}
                  >
                    환불불가 처리하기
                  </button>
                </>
              ) : detailRefundData[0]?.REFUND_STATUS == "complete" ? (
                <>
                  <button
                    type="button"
                    className="btn btn-outline-secondary me-1"
                    value={"wait"}
                    onClick={refundState}
                  >
                    환불대기 처리하기
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-success me-1"
                    value={"receipt"}
                    onClick={refundState}
                  >
                    환불접수 처리하기
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-danger"
                    value={"refusal"}
                    onClick={refundState}
                  >
                    환불불가 처리하기
                  </button>
                </>
              ) : detailRefundData[0]?.REFUND_STATUS == "refusal" ? (
                <>
                  <button
                    type="button"
                    className="btn btn-outline-secondary me-1"
                    value={"wait"}
                    onClick={refundState}
                  >
                    환불대기 처리하기
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-success me-1"
                    value={"receipt"}
                    onClick={refundState}
                  >
                    환불접수 처리하기
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-primary me-1"
                    value={"complete"}
                    onClick={refundState}
                  >
                    환불완료 처리하기
                  </button>
                </>
              ) : (
                <button
                  type="button"
                  className="btn btn-outline-secondary"
                  value={"wait"}
                  onClick={refundState}
                >
                  환불대기 처리하기
                </button>
              )
            ) : (
              <></>
            )}
            {isVerify ? (
              <button
                type="button"
                className="btn btn-outline-danger ms-2"
                onClick={() => {
                  deleteRefund();
                }}
              >
                삭제
              </button>
            ) : (
              <></>
            )}
          </div>
        </div>

        <div className="row bold-font">
          <div className="col d-flex flex-column mt-5">
            <label htmlFor="comment" className="form-label">
              환불신청 댓글
            </label>
            <div className="d-flex">
              <input
                type="text"
                className="form-control me-1"
                id="comment"
                style={{ width: "90%" }}
                ref={commentRef}
                onKeyDown={handleOnKeyPress}
              />
              <button
                type="button"
                className="btn btn-outline-dark"
                onClick={writeComment}
                style={{ whiteSpace: "nowrap" }}
              >
                댓글달기
              </button>
            </div>
          </div>
        </div>

        {commentMap().length > 0 ? (
          commentMap()
        ) : (
          <>
            <div className="mt-5"></div>
            <div className="container">
              <div className="row text-center">
                <div className="col">댓글이 없습니다.</div>
              </div>
            </div>
          </>
        )}
      </div>
      <Footer />
    </div>
  );
}

export default DetailRefund2;
