import React from "react";
import { useNavigate, Navigate, Outlet } from "react-router-dom";
import { useAxios } from "hooks/useAxios";

function PrivateRoute() {
  const { instance } = useAxios();
  const navigate = useNavigate();

  const isVerifyToken = async () => {
    try {
      await instance.post("/verify");

      if (sessionStorage.getItem("loginData")) {
        if (JSON.parse(sessionStorage.getItem("loginData")).access_token)
          return true;
      }

      return false;
    } catch (error) {
      console.log(error);
      navigate("/");
      return false;
    }
  };

  return isVerifyToken() ? <Outlet /> : <Navigate to="/login" />;
}

export default PrivateRoute;
