import { useState, useEffect } from "react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { useAxios } from "hooks/useAxios";

export const useGroupList = (serverUrl) => {
  const [pageNumberValue, setPageNumberValue] = useState(1);
  const [listData, setListData] = useState([]);
  const { accessAxios } = useAxios();
  const [loading, setLoading] = useState(false);

  const listMap = () => {
    const createList = listData.map((data, index) => (
      <tr key={data.id}>
        <td>{pageNumberValue * 30 - 30 + index + 1}</td>
        <td>{data.teamName}</td>
        <td>
          {data.isLeader === 1 ? (
            <span
              style={{
                background: "#36bdef",
                width: "10px",
                height: "10px",
                borderRadius: " 10px",
                display: "inline-block",
              }}
            ></span>
          ) : (
            <></>
          )}
          {data.name}
        </td>
        <td>{data.phoneNumber}</td>
        <td>{data.gender}</td>
        <td>{data.address}</td>
        <td>{data.detailAddress}</td>
        <td>{data.course}</td>
        <td>{data.cycle}</td>
        <td>{data.createdAt}</td>
      </tr>
    ));
    return createList;
  };

  const downloadExcelFileGroupList = async () => {
    try {
      const result = await accessAxios.post("/api/download-excel-group", null, {
        responseType: "blob",
      });

      const url = window.URL.createObjectURL(
        new Blob([result.data], { type: result.headers["content-type"] })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "2024년 여수시 그란폰도 단체참가자 목록");
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.log(error);
      Swal.fire({
        title: "엑셀 파일 다운로드 실패",
        text: "관리자에게 문의해주세요.",
        icon: "error",
        showConfirmButton: true,
      });
    }
  };

  useEffect(() => {
    let ignore = false;
    const fetchingData = async () => {
      try {
        if (!ignore) {
          setLoading(true);
        }
        const result = await accessAxios.post("/api" + serverUrl, {
          pageNumberValue,
        });
        if (!ignore) {
          setListData(result.data);
          setLoading(false);
        }
      } catch (error) {
        if (!ignore) {
          setLoading(false);
        }
        console.log(error);
        if (
          error.code === "ERR_BAD_RESPONSE" ||
          error.code === "ECONNABORTED"
        ) {
          Swal.fire({
            icon: "error",
            title: "서버 에러",
            text: "관리자에게 문의해주세요!!!",
          });
        }
      }
    };

    fetchingData();
    return () => {
      ignore = true;
    };
  }, [pageNumberValue, serverUrl]);

  return {
    listMap,
    setPageNumberValue,
    pageNumberValue,
    downloadExcelFileGroupList,
    loading,
  };
};
