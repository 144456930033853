import axios from "axios";
import { useNavigate } from "react-router-dom";

export const useAxios = () => {
  const navigate = useNavigate();
  let isAccessToken = "";

  if (sessionStorage.getItem("loginData")) {
    isAccessToken = JSON.parse(
      sessionStorage.getItem("loginData")
    ).access_token;
  }

  const instance = axios.create({
    baseURL: process.env.REACT_APP_SERVER_URL,
    timeout: 5000,
    headers: {
      Authorization: `Bearer ${isAccessToken}`,
      key: process.env.REACT_APP_CLIENT_SECRET_KEY,
    },
  });

  instance.interceptors.response.use(
    async (response) => {
      if (response.data.resultCode === "33") {
        const result = await accessAxios.post("/api/reissuance-token", {
          id: JSON.parse(sessionStorage.getItem("loginData")).id,
        });

        if (result.data.resultCode === "00") {
          sessionStorage.setItem("loginData", JSON.stringify(result.data.data));
        } else {
          axios.post("/api/logout");
          sessionStorage.removeItem("loginData");
          navigate("/login");
        }
      } else if (response.data.resultCode === "31") {
        axios.post("/api/logout");
        sessionStorage.removeItem("loginData");
        navigate("/login");
      }
      return response;
    },
    (error) => {
      console.log("useAxios 에러");
      console.log(error);
      return error;
    }
  );

  const accessAxios = axios.create({
    timeout: 5000,
    headers: {
      key: process.env.REACT_APP_CLIENT_SECRET_KEY,
    },
  });

  return { instance, accessAxios };
};
