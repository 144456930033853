import React from "react";

import { Editor, Viewer } from "@toast-ui/react-editor";
import "@toast-ui/editor/dist/toastui-editor.css";
import "tui-color-picker/dist/tui-color-picker.css";
import "@toast-ui/editor-plugin-color-syntax/dist/toastui-editor-plugin-color-syntax.css";
import colorSyntax from "@toast-ui/editor-plugin-color-syntax";
import "@toast-ui/editor-plugin-table-merged-cell/dist/toastui-editor-plugin-table-merged-cell.css";
import tableMergedCell from "@toast-ui/editor-plugin-table-merged-cell";

function TextEditor(props) {
  const colorSyntaxOptions = {
    preset: [
      "#333333",
      "#666666",
      "#FFFFFF",
      "#EE2323",
      "#F89009",
      "#009A87",
      "#006DD7",
      "#8A3DB6",
      "#781B33",
      "#5733B1",
      "#953B34",
      "#FFC1C8",
      "#FFC9AF",
      "#9FEEC3",
      "#99CEFA",
      "#C1BEF9",
    ],
  };
  const toolbarItemsOptions = [
    ["heading", "bold", "italic", "strike"],
    ["hr", "quote"],
    ["ul", "ol", "task", "indent", "outdent"],
    ["image", "link"],
    ["code", "codeblock"],
  ];

  return (
    <>
      <div className="mb-1">
        <label htmlFor="title" className="col-sm-2 col-form-label bold-font">
          제목 <span className="essential">*</span>
        </label>
        <input
          ref={props.hooksFunction.titleRef}
          type="text"
          id="title"
          className="form-control input-max-width"
          maxLength={100}
        />
      </div>
      {props.boardType === "question" || props.boardType === "refund" ? (
        <>
          <div className="col">
            <label htmlFor="questionWriter" className="form-label bold-font">
              작성자 <span className="essential">*</span>
            </label>
            <input
              ref={props.hooksFunction.writerRef}
              type="text"
              className="form-control"
              id="questionWriter"
              maxLength={10}
            />
          </div>
          <div className="col">
            <label htmlFor="questionPw" className="form-label bold-font">
              비밀번호 <span className="essential">*</span>
            </label>
            <input
              ref={props.hooksFunction.pwRef}
              type="password"
              className="form-control"
              id="questionPw"
              autoComplete="false"
              maxLength={10}
            />
          </div>
        </>
      ) : (
        <></>
      )}
      <div className="mb-3"></div>
      <Editor
        previewStyle="vertical"
        height="400px"
        initialEditType="wysiwyg"
        hideModeSwitch={true}
        toolbarItems={toolbarItemsOptions}
        plugins={[[colorSyntax, colorSyntaxOptions], tableMergedCell]}
        ref={props.hooksFunction.editorRef}
      />
    </>
  );
}

export default TextEditor;
