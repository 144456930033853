import React from "react";
import { Link } from "react-router-dom";
import { useVerifyLogin } from "hooks/useVerifyLogin";
import "../assets/css/Global.css";

function Header() {
  const { loginData, handleLogOut } = useVerifyLogin();

  return (
    <div>
      <nav className="navbar navbar-expand-lg bg-body-tertiary header-font">
        <div className="container-fluid">
          <Link className="navbar-brand brand-logo" to="/">
            <img
              src={require("assets/img/ycf_main_logo.png")}
              width={150}
              height={40}
            />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNavDropdown">
            <ul className="navbar-nav mx-auto">
              <li className="nav-item dropdown me-5">
                <Link
                  className="nav-link dropdown-toggle"
                  to="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  연맹소개
                </Link>
                <ul className="dropdown-menu">
                  <li>
                    <Link className="dropdown-item" to="/ycf-salutaion">
                      인사말
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/ycf-history">
                      연혁
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/ycf-info">
                      연맹소개
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/ycf-organization">
                      조직안내
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="nav-item dropdown me-5">
                <Link
                  className="nav-link dropdown-toggle"
                  to="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  참가신청
                </Link>
                <ul className="dropdown-menu">
                  <li>
                    <Link className="dropdown-item" to="/individual">
                      개인참가신청
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/group">
                      단체참가신청
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/individual-inquiry">
                      개인참가신청조회
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/group-inquiry">
                      단체참가신청조회
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="nav-item dropdown me-5">
                <Link
                  className="nav-link dropdown-toggle"
                  to="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  대회정보
                </Link>
                <ul className="dropdown-menu">
                  <li>
                    <Link className="dropdown-item" to="/info">
                      대회안내
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/course">
                      대회코스
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/picture">
                      대회사진
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/award">
                      시상안내
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/souvenir">
                      대회기념품
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/sponsor-page">
                      후원사
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/picture">
                      대회사진
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="nav-item pl-10 me-5">
                <Link className="nav-link" to="/last-competition">
                  지난대회
                </Link>
              </li>

              <li className="nav-item me-5">
                <Link className="nav-link" to="/record">
                  대회기록
                </Link>
              </li>
              <li className="nav-item dropdown">
                <Link
                  className="nav-link dropdown-toggle"
                  to="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  커뮤니티
                </Link>
                <ul className="dropdown-menu">
                  <li>
                    <Link className="dropdown-item" to="/notice2">
                      공지사항
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/frequent">
                      자주하는 질문
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/question2">
                      문의사항
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/refund2">
                      환불신청
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
            <div className="d-flex mr-2">
              {loginData?.loginType === "local" ? (
                <>
                  <ul className="navbar-nav">
                    <li className="nav-item dropdown">
                      <a
                        className="nav-link dropdown-toggle"
                        href="#"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {JSON.parse(sessionStorage.getItem("loginData"))
                          .name === "관리자"
                          ? "관리자님"
                          : JSON.parse(sessionStorage.getItem("loginData"))
                              .name}
                      </a>
                      <ul className="dropdown-menu">
                        <li>
                          <Link to="/admin">관리자 페이지</Link>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li
                          onClick={handleLogOut}
                          style={{ cursor: "pointer" }}
                        >
                          로그아웃
                        </li>
                      </ul>
                    </li>
                  </ul>
                </>
              ) : loginData?.loginType === "naver" ? (
                <>
                  <ul className="navbar-nav">
                    <li className="nav-item dropdown">
                      <a
                        className="nav-link dropdown-toggle"
                        href="#"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {loginData.info.name}님 환영합니다
                      </a>
                      <ul className="dropdown-menu">
                        <li
                          onClick={handleLogOut}
                          style={{ cursor: "pointer" }}
                        >
                          로그아웃
                        </li>
                      </ul>
                    </li>
                  </ul>
                </>
              ) : (
                <>
                  <Link to="/login">로그인</Link>
                </>
              )}
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Header;
