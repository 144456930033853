import { useEffect, useState } from "react";
import { useAxios } from "hooks/useAxios";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";

export const useAdminMain = () => {
  const [showAdminMainPageData, setShowAdminMainPageData] = useState([]);
  const { accessAxios } = useAxios();
  const [loading, setLoading] = useState(false);

  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  };

  useEffect(() => {
    let ignore = false;
    const fetchingData = async () => {
      try {
        if (!ignore) {
          setLoading(true);
        }
        const result = await accessAxios.post("/api/get-admin-main-page-data");

        if (result.data) {
          if (!ignore) {
            setLoading(false);
            setShowAdminMainPageData(result.data);
          }
        }
      } catch (error) {
        if (!ignore) {
          setLoading(false);
        }
        console.log(error);
        if (
          error.code === "ERR_BAD_RESPONSE" ||
          error.code === "ECONNABORTED"
        ) {
          Swal.fire({
            icon: "error",
            title: "서버 에러",
            text: "관리자에게 문의해주세요!!!",
          });
        }
      }
    };

    fetchingData();

    return () => {
      ignore = true;
    };
  }, []);

  return { numberWithCommas, showAdminMainPageData, loading };
};
