import React, { useState, useEffect, forwardRef, useRef } from "react";
import ReactDaumPost from "react-daumpost-hook";
import classnames from "classnames";

import "assets/css/Global.css";

const DaumPost = forwardRef((props, ref) => {
  const [address, setAddress] = useState({
    zipCode: "",
    address: "",
    detailAddress: "",
  });

  const setData = () => {
    props.getAddress(address);
  };

  const postConfig = {
    // 팝업창으로 사용시 ref: userRef()
    onComplete: (data) => {
      let addressAndBuildingName;

      if (data.buildingName !== "") {
        addressAndBuildingName =
          data.address + " " + "(" + data.buildingName + ")";
      } else {
        addressAndBuildingName = data.address;
      }
      ref.addressRef.current.value = addressAndBuildingName;
      setAddress({
        ...address,
        zipCode: data.zonecode,
        address: addressAndBuildingName,
      });
    },
  };
  const postCode = ReactDaumPost(postConfig);

  const handleDetailAddress = (e) => {
    setAddress({ ...address, detailAddress: e.target.value });
  };

  useEffect(() => {
    setData();
  }, [address]);

  return (
    <div>
      <div className="row mb-1">
        <label htmlFor="zipCode" className="col-sm-3 col-form-label bold-font">
          주소<span className="essential">*</span>
        </label>
        <div className="col-sm-9">
          <input
            type="text"
            className={classnames("form-control post-input-max-width", {
              "is-invalid": props.isValid.zipAndAddress,
            })}
            placeholder="우편번호"
            id="zipCode"
            readOnly
            value={address.zipCode}
            style={{ display: "inline", width: "60%" }}
          />
          <button
            type="button"
            className="btn btn-outline-primary bold-font ms-3 btntest"
            onClick={postCode}
          >
            주소찾기
          </button>
        </div>
      </div>
      <div className="row mb-1">
        <label htmlFor="address" className="col-sm-3 col-form-label"></label>
        <div className="col-sm-9">
          <input
            type="text"
            className={classnames("form-control  input-max-width", {
              "is-invalid": props.isValid.zipAndAddress,
            })}
            placeholder="주소"
            id="address"
            ref={ref.addressRef}
            readOnly
          />
        </div>
      </div>
      <div className="row mb-1">
        <label
          htmlFor="detailaddress"
          className="col-sm-3 col-form-label"
        ></label>
        <div className="col-sm-9">
          <input
            type="text"
            className={classnames("form-control  input-max-width", {
              "is-invalid": props.isValid.detailAddress,
            })}
            placeholder="상세주소"
            id="detailaddress"
            onChange={handleDetailAddress}
            ref={ref.detailAddressRef}
            maxLength={100}
          />
        </div>
      </div>
    </div>
  );
});

export default DaumPost;
